<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-01 14:22:27
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-12-01 10:06:13
-->
<template>
    <div class="functionUsageReport">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">功能使用报表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <commonSelect
                    @changeDate="changeDate"
                    @clickTagDate="clickTagDate"
                ></commonSelect>
            </div>
        </div>
        <div class="case-wrap select-wrap">
            <div class="select">
                <el-select class="sele" clearable filterable
                    @change="selectChangeMarket" v-model="epId" placeholder="请选择品牌筛选">
                    <el-option
                        v-for="item in epOptions"
                        :key="item.ep_id"
                        :label="item.name"
                        :value="item.ep_id">
                    </el-option>
                </el-select>
            </div>
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">数据汇总</div>
            </div>
            <div class="echart-wrap">
                <div class="content-item" v-loading="loading1">
                    <div class="item">
                        <div><span>{{countInfo.daily_active}}</span>活跃人数</div>
                        <div class="count">
                            <div class="c-item"><span>{{countInfo.count_user}}</span>总人数</div>
                            <div class="c-item"><span>{{countInfo.daily_active_user_rate}}</span>活跃占比</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-c"><span style="margin-bottom:10px">{{countInfo.daily_volume}}</span>使用功能总数</div>
                    </div>
                    <div class="item">
                        <div class="iten-c"><span>{{countInfo.usage}}</span>使用功能次数</div>
                    </div>
                    <div class="item">
                        <div class="item-c">
                            <div class="item-s"><span>{{countInfo.often_usage}}</span>小程序独立访客</div>
                            <!-- <el-tooltip effect="dark" placement="top-start">
                                <div slot="content">
                                    添加客户,更新客户线索,写跟进,录成交,写回访,分享名片<br/>
                                    创建备忘提醒,分享云店,分享产品,分享海报分享文章<br/>
                                    分享共享圈,下载共享圈,保存全景,分享全景打开场景<br/>
                                    创建渠道,渠道打卡,渠道维护,关联渠道客户<br/>
                                </div>
                                <i class="el-icon-warning"></i>
                            </el-tooltip> -->
                        </div>
                    </div>
                </div>
                <div v-loading="loading1" id="summaryGrades" style="width: 100%;height: 430px;"></div>
                <div class="title-wrap tit-con">
                    <div class="tag"></div>
                    <div class="title">运营功能分析</div>
                </div>
                <div v-loading="loading3" id="operationGrades" style="width: 100%;height: 430px;"></div>
                <div class="title-wrap tit-con">
                    <div class="tag"></div>
                    <div class="title">五大热点功能</div>
                </div>
                <div v-loading="loading2" id="oftenGrades" style="width: 100%;height: 430px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import commonSelect from "@/components/commonSelect.vue"
import {summaryEcharts,oftenEcharts,operationEcharts} from "@/echarts/lineEchartData";
export default {
    components: {
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'functionUsageReport',
            dateType: '',
            startDate: '',
            endDate: '',
            epId: '',
            epOptions: [],
            countInfo: {},
            loading1: false,
            loading2: false,
            loading3: false
        }
    },
    mounted () {
        this.getEpConditions();
        this.getSummary();
        this.getLineChart();
        this.getOftenChart();
        this.getOperateChart();
    },
    methods: {
        selectChangeMarket(){
            this.getSummary();
            this.getLineChart();
            this.getOftenChart();
            this.getOperateChart();
        },
        // 运营功能使用图示
        getOperateChart(){
            let params = {
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading3 = true;
            common.connect('/customerservicev1/data_report/operateFunctionSummaryLineChart',params,(res)=>{
                operationEcharts('operationGrades',res.data)
                this.loading3 = false;
            })
        },
        // 五大功能使用图示
        getOftenChart(){
            let params = {
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading2 = true;
            common.connect('/customerservicev1/data_report/oftenFunctionSummaryLineChart',params,(res)=>{
                res.data.series?res.data.series:res.data.series = [0,0,0,0,0];
                res.data.series1?res.data.series1:res.data.series1 = [0,0,0,0,0]
                oftenEcharts('oftenGrades',res.data)
                this.loading2 = false;
            })
        },
        // 获取汇总图标数据
        getLineChart(){
            let params = {
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading1 = true;
            common.connect('/customerservicev1/data_report/functionSummaryLineChart',params,(res)=>{
                summaryEcharts('summaryGrades',res.data)
                this.loading1 = false;
            })
        },
        // 获取汇总数据
        getSummary(){
            let params = {
                ep_id: this.epId,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            common.connect('/customerservicev1/data_report/functionSummary',params,(res)=>{
                this.countInfo = res.data;
            })
        },
        // 获取品牌下拉数据
        getEpConditions(){
            common.connect('/customerservicev1/enterprise_brand/EpConditions',{},(res)=>{
                this.epOptions = res.data
            })
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getSummary();
            this.getLineChart();
            this.getOftenChart();
            this.getOperateChart();
        },
        clickTagDate(item){
            this.dateType = item.label;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getSummary();
                this.getLineChart();
                this.getOftenChart();
                this.getOperateChart();
            }
        },
    },
}
</script>

<style scoped lang='scss'>
.functionUsageReport{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .tit-con{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
        .select{
            padding: 10px 0;
        }
        .echart-wrap{
            display: flex;
            flex-direction: column;
            align-items: center;
            .content-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .el-icon-warning{
                    color: #666;
                }
                .item{
                    width: 20%;
                    height: 110px;
                    border: 1px solid #ddd;
                    font-size: 18px;
                    color: #000;
                    margin-top: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .item-c{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .el-icon-warning{
                            margin-top: 12px;
                        }
                    }
                    .count{
                        padding-top: 10px;
                        font-size: 12px;
                        color: #000;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .c-item{
                            padding: 0 10px;
                        }
                        span{
                            font-size: 16px;
                            margin-right: 6px;
                            font-weight: bold;
                        }
                    }
                    span{
                        font-size: 28px;
                        margin-right: 10px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
